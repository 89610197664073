.toast-container {
  position: fixed;
  left: 15px;
  bottom: 20px;
}

.toast-item {
  position: relative;
  padding: 10px 16px;
  white-space: nowrap;
  border-radius: 3px;
  animation: toast-item--slide-in 250ms ease forwards;
}

@keyframes toast-item--slide-in {
  from { top: 70px; }
  to { top: 0; }
}

.toast-item--dismissed {
  animation: toast-item--slide-out 250ms ease forwards;
}

@keyframes toast-item--slide-out {
  from { top: 0; }
  to { top: 70px; }
}

.toast-item--info {
  background: var(--color-p1-3);
  color: var(--color-p1-8);
  border: 1px solid var(--color-p1-8);
}

.toast-item--success {
  background: var(--color-p2-2);
  color: var(--color-p2-6);
  border: 1px solid var(--color-p2-6);
}

.toast-item--error {
  background: var(--color-s3-2);
  color: var(--color-s3-6);
  border: 1px solid var(--color-s3-6);
}

.toast-item__close {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  top: -1px;
  margin-left: 10px;
}

.toast-item__close svg path {
  stroke: currentColor;
  fill: currentColor;
}
